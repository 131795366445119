import { createContext, useContext, useState } from 'react';

const StoreDataContext = createContext(undefined);

export function StoreDataContextWrapper({ children }: any) {
  const [loading, setLoading] = useState(true);
  const [facilityc, setFacilityC] = useState<any>([]);
  const [quickData, setQuickData] = useState<any>([]);
  const [facility, setFacility] = useState<any>([]);
  const [initial, setInitial] = useState<any>([]);
  const [storeData, setStoreDataa] = useState<any>([]);
  const [filterData, setFilterDataa] = useState<any>({});
  const [applyFilters, setApplyFilter] = useState<any>([]);
  const [resetpreFilters, setResetpreFilter] = useState<any>(false);

  const setResetpreFilters = (value: any) => {
    setResetpreFilter(value);
  };
  const setLoader = (value: any) => {
    setLoading(value);
  };

  function setFilterData(data: any) {
    setFilterDataa(data);
  }
  function setFacilitCity(data: any) {
    setFacilityC(data);
  }
  function setquickSearchInfo(data: any) {
    setQuickData(data);
  }
  function setFacilit(data: any) {
    setFacility(data);
  }
  function initialData(data: any) {
    setInitial(data);
  }
  function setApplyFilters(data: any) {
    setApplyFilter(data);
  }
  function setStoreData(data: any) {
    setStoreDataa(data);
  }
  const sharedState = {
    storeData,
    setStoreData,
    filterData,
    setFilterData,
    applyFilters,
    setApplyFilters,
    initial,
    initialData,
    facility,
    setFacilit,
    facilityc,
    setFacilitCity,
    quickData,
    setquickSearchInfo,
    loading,
    setLoader,
    resetpreFilters,
    setResetpreFilters,
  };

  return (
    <StoreDataContext.Provider value={sharedState}>
      {children}
    </StoreDataContext.Provider>
  );
}

export function useAppContext() {
  return useContext(StoreDataContext);
}

export default StoreDataContext;
