import { createContext, useContext, useState } from 'react';

import { PageType } from '@/utils/constants';

const MapCordinateContext = createContext(undefined);

export function MapCordinateContextWrapper({ children }: any) {
  const [cordinate, setCordinate] = useState<any>();

  const [latLng, setLatLng] = useState<any>({});

  const [latLngc, setLatLngC] = useState<any>({});

  const [mapSearch, setMapSearch] = useState<any>();
  const [searchCheck, setSearchCheck] = useState(true);
  const [latLang, setLatLang] = useState<any>({});
  const [address, setAddress] = useState<any>('');
  const [newSearchCheck, setNewSearchCheck] = useState<any>({});
  const [searchInput, setSearchInput] = useState<any>(false);

  function setSisterInput(data: any) {
    setSearchInput(data);
  }

  function setNewSearch(data: any) {
    setNewSearchCheck(data);
  }
  function setAddresses(data: any) {
    setAddress(data);
  }
  function setLatLangs(data: any) {
    setLatLang(data);
  }

  function setSearchChecked(data: any) {
    setSearchCheck(data);
  }
  function setMapSearchMove(data: any) {
    setMapSearch(data);
  }

  function setCordinateData(data: any) {
    setCordinate(data);
  }

  function setLatLog(data: any) {
    setLatLng(data);
  }

  function setLatLogCity(data: any) {
    setLatLngC(data);
  }

  const getPageType = () => {
    if (Object.keys(latLngc)?.length) {
      return PageType.CityLandingPage;
    }
    if (Object.keys(latLng)?.length) {
      return PageType.FacilityPage;
    }
    return PageType.StoreLocatorPage;
  };

  const sharedState = {
    cordinate,
    setCordinateData,
    latLng,
    setLatLog,
    latLngc,
    setLatLogCity,
    mapSearch,
    setMapSearchMove,
    searchCheck,
    setSearchChecked,
    latLang,
    setLatLangs,
    address,
    setAddresses,
    newSearchCheck,
    setNewSearch,
    setSisterInput,
    searchInput,
    getPageType,
  };

  return (
    <MapCordinateContext.Provider value={sharedState}>
      {children}
    </MapCordinateContext.Provider>
  );
}

export function useAppContext() {
  return useContext(MapCordinateContext);
}

export default MapCordinateContext;
