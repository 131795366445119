import { createContext, useContext, useState } from 'react';

const LocationContext = createContext(undefined);

export function LocationContextWrapper({ children }: any) {
  const [location, setLocation] = useState<any>();
  function setLocationData(data: any) {
    setLocation(data);
  }
  const sharedState = {
    location,
    setLocationData,
  };

  return (
    <LocationContext.Provider value={sharedState}>
      {children}
    </LocationContext.Provider>
  );
}

export function useAppContext() {
  return useContext(LocationContext);
}

export default LocationContext;
